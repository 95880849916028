%full-page {
  background-color: $bg-800;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  // display: grid;
}

%main-page {
  padding: 0px 32px;

  @include media("<=phone") {
    padding: 0px 16px;
  }
}

// TODO; fix text styles and grids
