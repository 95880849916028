@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");

// Text styles
%text-xs {
  font-size: 1.2rem;
  line-height: 1.8rem;
}

%text-sm {
  font-size: 1.4rem;
  line-height: 2rem;
}

%text-md {
  font-size: 1.6rem;
  line-height: 2.4rem;
}

%text-lg {
  font-size: 1.8rem;
  line-height: 2.8rem;
}

%text-xl {
  font-size: 2rem;
  line-height: 3rem;
}

%display-xs {
  font-size: 2.4rem;
  line-height: 3.2rem;
}

%display-sm {
  font-size: 3rem;
  line-height: 3.8rem;
}

%display-md {
  font-size: 3.6rem;
  line-height: 4.4rem;
  font-style: normal;

  @include media("<=phone") {
    font-size: 2.4rem;
    line-height: 3.2rem;
  }
}

%display-lg {
  font-size: 4.8rem;
  line-height: 6rem;

  @include media("<=phone") {
    font-size: 3rem;
    line-height: 3.8rem;
  }
}

%display-xl {
  font-size: 6rem;
  line-height: 7.2rem;
}

%display-2xl {
  font-size: 7.2rem;
  line-height: 9rem;
}
