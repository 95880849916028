* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

// html {
//   font-size: calc(100vw / 1440 * 10);

//   @include media("<=phone") {
//     font-size: calc(100vw / 425 * 10);
//   }
// }

body,
* {
  //   @extend %text-md;

  font-family: Inter, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  // font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
}

body {
  position: fixed;
  width: 100%;
  height: 100%;
}

textarea,
select,
input,
button {
  outline: none;
}

// .toast {
//   background-color: red;
//   border: none;
//   padding: 0px;
//   margin: 0px;
// }

// .toast.success {
//   background-color: #2e7d32;
//   color: white;
//   border-radius: 12px;
//   font-weight: 500;
//   font-family: "Inter";
//   margin-top: 32px;
// }

// .toast.error {
//   background-color: #ff3344;
//   color: white;
//   border-radius: 12px;
//   font-weight: 500;
//   font-family: "Inter";
//   margin-top: 32px;
// }

// html.theme-dark #widget-container {
//   border-color: red;
// }
// #widget-container {
//   border-color: blue;
// }

// iframe {
//   filter: xray(33%);
// }

// .tradingview-widget-container {
//   height: 300px;
//   background: red;
//   position: relative;
// }

// .tradingview-widget-container::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   right: 0;
//   border-top: 80px solid white;
//   border-left: 80px solid red;
//   width: 0;
// }

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* width */
.scrollbar-custom::-webkit-scrollbar {
  width: 10px;
  height: 30px;
}

/* Track */
.scrollbar-custom::-webkit-scrollbar-track {
  background: red;
  border-radius: 10px;
}

/* Handle */
.scrollbar-custom::-webkit-scrollbar-thumb {
  background: blue;
  max-height: 20px;
  border-radius: 10px;
}

/* Handle on hover */
.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/*
 *  STYLE 4
 */

.style-4::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
}

.style-4::-webkit-scrollbar {
  width: 8px;
  // height: 10px;
  background-color: transparent;
}

.style-4::-webkit-scrollbar-thumb {
  background-color: #1b1f2d;
  border: 2px solid #1b1f2d;
  border-radius: 32px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.highcharts-series path {
  stroke: #151823;
}
